import Home from './components/Home.vue';
import WebAndalucia from './components/WebAndalucia.vue';
import Quiz from './components/Quiz.vue';
import Categories from './components/Categories.vue';
import Category from './components/Category.vue';

const pathPrefix = (process.env.NODE_ENV === 'production') ? "" : "";

export const routes = [
    {
        name: 'home',
        path: pathPrefix + '/',
        component: Home
    },
    {
        name: 'webAndalucia',
        path: pathPrefix + '/web-andalucia',
        component: WebAndalucia
    },
    {
        name: 'quiz',
        path: pathPrefix + '/quiz',
        component: Quiz
    },
    {
        name: 'categories',
        path: pathPrefix + '/categories',
        component: Categories
    },
    {
        name: 'category',
        path: pathPrefix + '/category/:category',
        component: Category
    },
];