<template>
    <div class="selectorCategoriasFolletos">
      <div class="col3">
        <div class="category">
          <router-link to="/category/folletos-especializados">
            <img :src="`/img/folletos-especializados-${lang}.png`">
          </router-link>
        </div>
        <div style="width:40px !important"></div>
        <div class="category">
          <router-link to="/category/folletos-de-costas">
          <img :src="`/img/folletos-de-costas-${lang}.png`">
          </router-link>
        </div>
        <div style="width:40px"></div>
        <div class="category">
          <router-link to="/category/folletos-genericos">
          <img :src="`/img/folletos-genericos-${lang}.png`">
          </router-link>
        </div>
      </div>
      <div style="height: 40px"></div>
      <div class="col2">
        <div class="category">
          <router-link to="/category/folletos-de-ciudades">
          <img :src="`/img/folletos-de-ciudades-${lang}.png`">
          </router-link>
        </div>
        <div style="width:40px"></div>
        <div class="category">
          <router-link to="/category/andalucia-eleccion-natural">
          <img :src="`/img/folletos-naturalmente-${lang}.png`">
          </router-link>
        </div>
      </div>
    </div>
</template>
 
<script>
export default {
        data() {
            return {
                textos: {
                    "esp": {
                        "folletos-especializados": "Folletos Especializados",
                    },
                    "ing": {
                        "folletos-especializados": "Specalised Brochures",
                    }
                },
            }
        },
        props: ['lang'],
        mounted() {
            console.log("LANG:" + this.lang)
        },
        methods: {
        }
}
</script>