<template>
<div class="contentInner">
    <div class="selectorInicial">
        <div>
          <router-link to="/categories">
            <img :src="`/img/home-folletos-${lang}.png`">
          </router-link>
        </div>
      <div style="text-align: center;"><img @click="$refs.modalName.openModal()" :src="`/img/home-web-andalucia-${lang}.png`"></div>
      <div style="text-align: right;">
        <router-link to="/quiz">
          <img :src="`/img/home-quiz-${lang}.png`">
        </router-link>
      </div>
    </div>
    <modal ref="modalName">
      <template v-slot:header>
        www.andalucia.org
      </template>

      <template v-slot:body>
        <iframe :src="`${iFrameURL}`" width="1600" height="900" frameborder="0" allowfullscreen style="width: 100%; z-index: 1"></iframe>
      </template>

      <template v-slot:footer>
      </template>
    </modal>
</div>
</template>
 
<script>
import Modal from "./Modal";
export default {
  data() {
      return {
        iFrameURL: ""
      }
  },
  components: {
    Modal
  },
  props: ['lang'],
  mounted() {
      // this.lang = this.$router.
      console.log("LANG:" + this.lang)
      this.iFrameURL = (this.lang === "esp") ? "https://www.andalucia.org/es/" : "https://www.andalucia.org/en/home"
  },
  watch: {
    lang: function (newLang) {
      this.iFrameURL = (newLang === "esp") ? "https://www.andalucia.org/es/" : "https://www.andalucia.org/en/home"
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.overflow-hidden {
  overflow: hidden;
}
</style>

