<template>
  <div class="contentInner">
      <div class="quiz">
        <div class="video">
          <div class="videowrapper">
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/WXmKr9WOdr0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
            <video width="1920" height="1080" poster="/img/reaae.jpg" autoplay="true">
              <source :src="`/img/reaae-${lang}.mp4`" type="video/mp4">
            </video>
          </div>
        </div>
        <div class="cta">
          <div>
            <img style="width: 235px" :src="`/img/escanea-y-participa-${lang}.png`">
          </div>
          <div>
            <img style="width: 75px; margin-top: 10px" :src="`/img/qr-quiz-${lang}.png`">
          </div>
        </div>
      </div>
      <modal ref="modalName">
        <template v-slot:header>
          Quiz
        </template>

        <template v-slot:body>
          <iframe :src="`${iFrameURL}`" width="1600" height="900" frameborder="0" allowfullscreen style="width: 100%; z-index: 1"></iframe>
        </template>

        <template v-slot:footer>
        </template>
      </modal>
  </div>
</template>
 
<script>
import Modal from "./Modal";
export default {
  data() {
      return {
        iFrameURL: ""
      }
  },
  components: {
    Modal
  },
  props: ['lang'],
  mounted() {
      // this.lang = this.$router.
      console.log("LANG:" + this.lang)
      this.iFrameURL = (this.lang === "esp") ? "https://www.feriasturismoandaluz.com/quiz/2" : "https://www.feriasturismoandaluz.com/quiz/3"
  },
  watch: {
    lang: function (newLang) {
      this.iFrameURL = (newLang === "esp") ? "https://www.feriasturismoandaluz.com/quiz/2" : "https://www.feriasturismoandaluz.com/quiz/3"
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.overflow-hidden {
  overflow: hidden;
}
.video {
  width: 60vw;
  margin: 0 auto;
}
.videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  video, iframe, .videowrapper object, .videowrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.cta {
  text-align: center;
  margin-top: 50px;
  div {
    text-align: center;
  }
  a {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
    padding: 10px 25px;
    border: 2px solid #fff;
    border-radius: 50px;
    font-size: 27px;
    transition: all .2s ease-out;
    &:hover {
      background-color: #C63468;
      border-color: #C63468;
    }
  }
}
</style>

