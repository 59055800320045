<template>
<div class="contentInner">
  <div class="webAndalucia">
    <iframe :src="iframeURL" width="1600" height="900" frameborder="0" allowfullscreen></iframe>
  </div>
</div>
</template>
 
<script>
export default {
  data() {
      return {
      }
  },
  props: ['lang'],
  mounted() {
      // this.lang = this.$router.
      console.log("LANG:" + this.lang)
  },
  computed: {
    iframeURL: () => {
      return (this.lang === "esp") ? "https://www.andalucia.org/es/" : "https://www.andalucia.org/en/home"
    }
  },
  methods: {
  }
}
import $ from 'jquery'
    $("html").contextmenu(function(e) {
       e.preventDefault();
    });
</script>