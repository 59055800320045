import { createApp } from 'vue'
import App from './App.vue'
import * as VueRouter from 'vue-router'

import { routes } from './routes'

import $ from 'jquery'; 

import VueQRCodeComponent from 'vue-qr-generator'

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

// createApp(App).mount('#app')

const app = createApp(App).use(router)
app.component('qr-code', VueQRCodeComponent)
app.mount('#app')

setInterval(function() { getWallValues() }, 1000);

function getWallValues() {
  var domain = window.location.hostname;
  var baseUrl
  if(domain==='localhost') {
    baseUrl = "http://localhost:8080";
  }
  else {
    baseUrl = "https://" + domain;
  }

  if(domain!=='localhost') {
    $.getJSON(baseUrl + "/get-connection-status.php")
      .done(function(response) {
        manageWall(response)
      })
      .fail(function() {
        manageWall(0)
      })
    ;
  }
}

function manageWall(data) {
  // Si se ha desconectado la pantalla en el administrador o se ha perdido la conexión con el servidor
  if(data==0 || data==false) {
    // OFF
    $(".header").hide();
    $(".contentWrapper.on").hide();
    $(".contentWrapper.off").show();
    console.log("OFF");
  }
  if(data==1) {
    // ON
    $(".header").show();
    $(".contentWrapper.on").show();
    $(".contentWrapper.off").hide();
    console.log("ON");
  }
}


// Detectar Idle Time y regresar a /
var time;
document.getElementById("app").addEventListener('mousemove', function(){
  resetTimer();
});
document.getElementById("app").addEventListener('mouseup', function(){
  resetTimer();
});
function resetTimer()  {
  clearTimeout(time);
  time = setTimeout(gotoHome, 60000)
}
function gotoHome() {
  // router.push('/');
}
