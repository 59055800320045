<template>
    <div class="selectorFolletos">
      <div class="folletos">
        <div v-for="(item, index) in current" :key="item" ref="brochure">
            <img :src="`/img/portadas/${item[0]}-${lang}.jpg`" @click="showQr(item); selected = index" :class="{selected:index == selected}">
        </div>
      </div>
      <!-- <div class="scrollbar"></div> -->
      <div class="qr" style="flex-wrap: wrap; align-items: center; flex-direction:column">
        <div style="font-size: 30px; width: 100%; text-align: center; margin-bottom: 40px;" v-if="qr">{{ descargateText }}</div> 
        <div class="item" v-if="qrCode">
        <qr-code 
            :text="qrCode"
            size="350"
            color="#000000"
            bg-color="#ffffff" 
            error-level="L"
            :is="qrComponentKey"
            >
        </qr-code>
          <!-- <img :src="`/img/qr/${qr}-${lang}.png`" alt=""> -->
        </div>
      </div>
    </div>
</template>
 
<script>
    export default {
        data() {
            return {
                folletos: {
                   "esp": {
                       "folletos-de-ciudades": [
                            ["sevilla", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/92F570E7B5F44DACB1476B25CDF2E4F9/guia_practica_ciudad_sevilla.pdf"],
                            ["granada", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/402EC5043DBF4083BD596B4A9BA9ECAE/guia_practica_ciudad_granada.pdf"],
                            ["almeria", "https://multimedia.andalucia.org/media/B6823FB3C9364FCE91539A02696B8CDA/doc/0150252BA9AA46E4BB23F67F62B2308D/guia_practica_ciudad_almeria_2.pdf"],
                            ["cadiz", "https://multimedia.andalucia.org/media/0F51F3A070E9419BB45D993D90F65D82/doc/E559539107DC419C8574511E1D4BA129/guia_practica_ciudad_cadiz.pdf"],
                            ["malaga", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/FD5842679ABE40B492A8747B776A7820/guia_practica_ciudad_malaga.pdf"],
                            ["cordoba", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/B9EFF443AA62439A9A61E41B875075E2/guia_practica_ciudad_cordoba.pdf"],
                            ["huelva", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/C66B04876A2E4DF2A5CEF0440A55B589/guia_practica_ciudad_huelva.pdf"],
                            ["jaen", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/16E23FEC193F4987A872F873EB448F87/guia_practica_ciudad_jaen.pdf"],
                            ["ciudades-medias", "https://multimedia.andalucia.org/media/E6518293CCFD4788BEB76B648B473434/doc/975240B80F0E4CD498CE06693BDD620A/ciudades_medias_ES.pdf"],
                        ],

                       "folletos-especializados": [
                            ["andalucia-insolita", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/CE870C9382154259BF1E27BF4767A8DC/Guia_de_la_Andalucia_insolita.pdf"],
                            ["turismo-de-interior-y-naturaleza", "https://multimedia.andalucia.org/media/04EB6B3B3D1F43D3B0D81B720929A4C8/doc/24CF6F7C8ED040E79013776868F7B308/guia_practica_turismo-de-interior-y-naturaleza.pdf"],
                            ["serrania-de-ronda", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/052C36F9041742FDA9000AEB7E239CA9/Guia_practica_Serrania_de_Ronda.pdf"],
                            ["turismo-familiar", "https://multimedia.andalucia.org/media/3D5EFE7C9AAA470FB8DC8B1BD6E55744/doc/024A6E062AFC43999A225BA3ADC752E8/guia_practica_turismo-familiar.pdf"],
                            ["turismo-gastronomico", "https://multimedia.andalucia.org/media/CA07D9F04F2B4B1A9B1827423F37B22F/doc/CD0182CD53D84953A1E83E3BDC9FABE1/guia_practica_turismo_gastronomico.pdf"],
                            ["turismo-ornitologico", "https://multimedia.andalucia.org/media/25FF5510329144608699F4BEE68CF42E/doc/60B376468CAB4D438B2078BC93716F73/guia_practica_turismo_ornitologico.pdf"],
                            ["turismo-enologico", "https://multimedia.andalucia.org/media/5B6C6EC4A06C49058290B351E3B1192A/doc/E1A766A6FA9F49C39F77256786D5245F/guia_practica_turismo_enologico_1.pdf"],
                            ["turismo-de-cruceros", "https://multimedia.andalucia.org/media/D9CDC1651341473A9F66DBED89F8A794/doc/D6B43357996B4426BA166964FAC85B97/guia_practica_turismo-de-cruceros.pdf"],
                            ["turismo-de-salud", "https://multimedia.andalucia.org/media/672616BB99434AB0BEC0AA6296E9BE19/doc/4C8D78B86E6C4C1C9A6890F18C8DF244/guia_practica_turismo-de-salud.pdf"],
                            ["flamenco", "https://multimedia.andalucia.org/media/988BE137F8E94C22A30F48CFAFB43EB2/doc/E27AC8C3EE204844A9F36BD034B830B3/guia_practica_flamenco_ESP.pdf"],
                            ["andalucia-con-orgullo", "https://multimedia.andalucia.org/media/4BE1595BF6754BC58D1401F382322F51/doc/557A3E81040A43AF8EEAAC646250D97E/guia-lgtbi-ES_1.pdf"],
                            ["actividades-nauticas-y-puertos-deportivos", "https://multimedia.andalucia.org/media/55BC7BB9183B44CDA85CC77DDC386E7D/doc/1D8D9084A2D546C7AFD64D55E1850992/guia_practica_actividades-nauticas-y-puertos-deportivos.pdf"],
                            ["andalucia-mice", "https://multimedia.andalucia.org/media/ED8074DC2E164BFE81346414C0C8BE34/doc/89595C1AD3F748F697F093AD498FE4AB/guia_mice_ES.pdf"],
                            ["golf", "https://multimedia.andalucia.org/media/D62FC05709234343A4EA904A51E1BDFB/doc/B46C37F35DAF488BA4202D5E1D1A89F9/Golf_ESP.pdf"],
                            ["senderismo", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/3F84E432E93C4919AD95BA2D4745A2E3/guia_practica_senderismo.pdf"],
                            ["senderismo-guia", "https://multimedia.andalucia.org/media/1398F74AAEA7430C9DA650EBB7B696D8/doc/B0F81507D4BC44F99FE98C0C15E30911/Guia_Senderismo.pdf"],
                            ["cicloturismo", "https://multimedia.andalucia.org/media/2D57B00D919D43CF8F112BC9297E1258/doc/B80D2C73A9FE44D7A508BD6CBCD80AB5/Guia_Cicloturismo.pdf"],
                        ],

                       "folletos-de-costas": [
                            ["costa-de-la-luz-cadiz", "https://multimedia.andalucia.org/media/39E3AEE7BE2D4855840D4A36A06EDD51/doc/C68B5AC7083D4ED8A7A03AA1EB55BA48/guia_practica_costa_la-luz-cadiz_2.pdf"],
                            ["costa-de-la-luz-huelva", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/8FA41195468B45A48CA00E6B275F2F32/guia_practica_costa_la_luz_huelva.pdf"],
                            ["costa-tropical-granada", "https://multimedia.andalucia.org/media/DA3778779C6F4D5FA7AABD4415C2E40A/doc/EAB8F0D8DD21420CB4A1FDCB2BEFC69F/guia_practica_costa_tropical.pdf"],
                            ["costa-almeria", "https://multimedia.andalucia.org/media/AB17D94FB2714521B9F77306EA686A8D/doc/CAE1B1442AFA4AA1A60016B5C91522C1/guia_practica_costa_almeria_2.pdf"],
                            ["costa-del-sol", "https://multimedia.andalucia.org/media/4DD9B0BE61CA4826BA2BF9DCA3BE89B9/doc/FE4CDFE41847459B89B5D1EBEF781A8B/guia_practica_costa_del-sol.pdf"]
                        ],

                       "folletos-genericos": [
                            ["guia-practica-andalucia", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/0AC56B16437C4599B052BC035F344CFB/guia_practica_andalucia.pdf"]
                        ],

                       "andalucia-eleccion-natural": [
                            ["guia-andalucia-eleccion-natural", "https://vfitur2022.abbsoluteonline.com/images/pdf/Guia_de_Andalucia.pdf"]
                        ],
                    },
                   "ing": {
                       "folletos-de-ciudades": [
                            ["sevilla", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/412526F9E8EE4A2B8140FDABACCFDB13/practical_city_guide_sevilla.pdf"],
                            ["granada", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/36874CE1F649404CA72001D9BA682E24/practical_city_guide_granada.pdf"],
                            ["almeria", "https://multimedia.andalucia.org/media/B6823FB3C9364FCE91539A02696B8CDA/doc/A12ABD4303D249B084CB6ED408DE177F/practical_city_guide_almeria.pdf"],
                            ["cadiz", "https://multimedia.andalucia.org/media/0F51F3A070E9419BB45D993D90F65D82/doc/0FF5D46D964643F58FC9B99A327DA171/practical_city_guide_cadiz.pdf"],
                            ["malaga", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/DE4B51C042274C72920BC7E8937007B6/practical_city_guide_malaga.pdf"],
                            ["cordoba", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/76AAF4DD8764403AAF98C0D4A2606EF7/practical_city_guide_cordoba.pdf"],
                            ["huelva", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/DC217EE90BED40CB8B1E994DE0F67E1A/practical_city_guide_huelva.pdf"],
                            ["jaen", "https://multimedia.andalucia.org/media/9B051D34AE9540CC94823AD8970379B9/doc/925C8DF70ED44ECBA8EE71A868D0A9F9/practical_city_guide_jaen_1.pdf"],
                            ["ciudades-medias", "https://multimedia.andalucia.org/media/E6518293CCFD4788BEB76B648B473434/doc/2D54F375F1414BBB949245551996184D/ciudades_medias_EN.pdf"],
                        ],

                       "folletos-especializados": [
                            ["andalucia-insolita", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/7E3FFBA2366A4AE9A364B12608CD6808/Guide_for_Andalucia_unexpected.pdf "],
                            ["turismo-de-interior-y-naturaleza", "https://multimedia.andalucia.org/media/04EB6B3B3D1F43D3B0D81B720929A4C8/doc/B43AA945461E428595309273A508692C/practical_guide_inner-and-nature-tourism.pdf"],
                            ["serrania-de-ronda", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/21F25929898E49C08758AAFA3AF375BD/Practical_guide_Serrania_de_Ronda.pdf"],
                            ["turismo-familiar", "https://multimedia.andalucia.org/media/3D5EFE7C9AAA470FB8DC8B1BD6E55744/doc/6ADC996AC8564263A1958BC068E37D2E/practical_guide_family-friendly-tourism.pdf"],
                            ["turismo-gastronomico", "https://multimedia.andalucia.org/media/CA07D9F04F2B4B1A9B1827423F37B22F/doc/075429C342064564A7C99CD6964FC511/practical_guide_gastronomic-tourism.pdf"],
                            ["turismo-ornitologico", "https://multimedia.andalucia.org/media/25FF5510329144608699F4BEE68CF42E/doc/59A1839A93C04D0B9F4221B8F6A362FF/practical_guide_bird-watching_tourism.pdf"],
                            ["turismo-enologico", "https://multimedia.andalucia.org/media/5B6C6EC4A06C49058290B351E3B1192A/doc/F96C1A6412164E8CA8DD79E62F479576/practical_guide_wine_tourism.pdf"],
                            ["turismo-de-cruceros", "https://multimedia.andalucia.org/media/D9CDC1651341473A9F66DBED89F8A794/doc/E81783843E9E40BBA9FBDDF7155C4FC2/practical_guide_cruise-ship-tourism.pdf"],
                            ["turismo-de-salud", "https://multimedia.andalucia.org/media/672616BB99434AB0BEC0AA6296E9BE19/doc/89B2C3DFA1C04562BD2F91A805EC4BD6/practical_guide_health-tourism.pdf"],
                            ["flamenco", "https://multimedia.andalucia.org/media/988BE137F8E94C22A30F48CFAFB43EB2/doc/60A82C7CEC12403583C7AF3377C76198/practical_guide_flamenco_ING.pdf"],
                            ["andalucia-con-orgullo", "https://multimedia.andalucia.org/media/4BE1595BF6754BC58D1401F382322F51/doc/89043AF078C44B6AAAAE55D9DBBC0D7A/guia-lgtbi-EN_1.pdf"],
                            ["actividades-nauticas-y-puertos-deportivos", "https://multimedia.andalucia.org/media/55BC7BB9183B44CDA85CC77DDC386E7D/doc/2AD2378FB45F494F8008B6BAC5182DF4/practical_guide_nautical-activities-and-marinas.pdf"],
                            ["andalucia-mice", "https://multimedia.andalucia.org/media/ED8074DC2E164BFE81346414C0C8BE34/doc/11A3E3F111434C2E916EA16EC3BF8D11/guia_mice_EN.pdf"],
                            ["golf", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/10B0C06290BA4DE7967D97A15212139F/golf.pdf"],
                            ["senderismo", "https://multimedia.andalucia.org/media/133D8AF11C6F454D9CA204F522413371/doc/FDFF687E63584DA2A50D2EC0338857C5/practical_guide_walking.pdf"],
                            ["senderismo-guia", "https://multimedia.andalucia.org/media/1398F74AAEA7430C9DA650EBB7B696D8/doc/B0F81507D4BC44F99FE98C0C15E30911/Guia_Senderismo.pdf"],
                            ["cicloturismo", "https://multimedia.andalucia.org/media/2D57B00D919D43CF8F112BC9297E1258/doc/268D2FB91B63428485C3C09CF2E4F7ED/Guide__Cycle_Tourism.pdf"],
                        ],

                       "folletos-de-costas": [
                            ["costa-de-la-luz-cadiz", "https://multimedia.andalucia.org/media/39E3AEE7BE2D4855840D4A36A06EDD51/doc/92AB220994F5463B8B54FCA5B421F545/practical_guide_costa_la_luz_cadiz.pdf"],
                            ["costa-de-la-luz-huelva", "https://multimedia.andalucia.org/media/EEDE484792DC400D8CB064DEA1A4C315/doc/7697AED978884D219993832934A39339/practical_guide_costa_la_luz_huelva.pdf"],
                            ["costa-tropical-granada", "https://multimedia.andalucia.org/media/DA3778779C6F4D5FA7AABD4415C2E40A/doc/2C59D09DAE234A3D9762362DA9413ADE/practical_guide_costa_tropical.pdf"],
                            ["costa-almeria", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/CA93B7B95606412CABB508DFCEBD0CC0/practical_guide_costa_almeria.pdf"],
                            ["costa-del-sol", "https://multimedia.andalucia.org/media/4DD9B0BE61CA4826BA2BF9DCA3BE89B9/doc/CAECF186EA354FDBBEECFFE39FC75B5C/practical_guide_costa_del_sol.pdf"],
                        ],

                       "folletos-genericos": [
                            ["guia-practica-andalucia", "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/doc/FC76F4E298E54CE092FFDDC462E65D6A/practical_guide_andalucia.pdf"]
                        ],

                       "andalucia-eleccion-natural": [
                            ["guia-andalucia-eleccion-natural", "https://vfitur2022.abbsoluteonline.com/images/pdf/Guia_de_Andalucia.pdf"]
                        ],
                    }
                },
                current: [],
                qrCode: undefined,
                selected: undefined,
                descargateText:"Descárgate este folleto en tu móvil",
                qrComponentKey: 0,
            }
        },
        props: ['lang'],
        created() {
            console.log(this.$route.params.category)
            this.current = this.folletos[this.lang][this.$route.params.category]
            this.descargateText = (this.lang =="esp") ?"Descárgate este folleto en tu móvil" :"Download this brochure to your cell phone"
            // this.qr = this.current[0]
        },
        mounted() {
            if(this.current.length === 1) {
                this.showQr(this.current[0])
            }
        },
        methods: {
            // setLanguage(lang){
            //   console.log(lang)
            //   this.$router.push('/')
            // }
            showQr(item) {
                this.qrCode = item[1]
                this.qrComponentKey += 1
                // console.log(item[1])
                // console.log(this.selected)
                // console.log(this.lang)
            },
        }
    }
</script>