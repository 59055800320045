<template>
  <div class="mainWrapper">
    <div class="header">
      <div class="headerContent">
        <a href="" class="irAtras" @click.prevent="$router.go(-1)">
          <img src="./assets/img/btn-back.png">
        </a>
        <div class="logo"><img src="./assets/img/andalucia-el-gran-escenario-de-la-alegria.png"></div>
          <div class="langs" style="min-width: 110px;">
            <router-link to="/" class="es" @click.prevent="setLanguage('esp')" style="margin-right: 40px"><img src="./assets/img/btn-esp.png"></router-link>
            <router-link to="/ing" class="en" @click.prevent="setLanguage('ing')"><img src="./assets/img/btn-ing.png"></router-link>
          </div>
          <!-- <a href="" class="irSiguiente" @click.prevent="$router.go(1)">
            <img src="./assets/img/btn-next.png">
          </a> -->
      </div>
    </div>
    <div class="contentWrapper on">
      <div class="content">
        <router-view :lang="lang"> </router-view>
      </div>
    </div>
    <div class="contentWrapper off">
      <div class="content">
        <div class="logo animated pulse infinite">
          <img src="./assets/img/andalucia-logo-big.png">
        </div>
      </div>
    </div>
</div>
</template>

<script>
import './scss/style.scss'

export default {
        data() {
            return {
                aaa: {},
                bbb: "",
                lang: "esp",
            }
        },
        created() {
            
        },
        methods: {
            setLanguage(lang){
              console.log(lang)
              this.lang = lang
              this.$router.push('/')
            },
        },

        mounted() {
        }

}
</script>